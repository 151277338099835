import React from 'react';
import {graphql, navigate} from 'gatsby'

import Layout from '../components/Layout';

const StatusPageTemplate = (props)=> {
    const content = props.data.markdownRemark.frontmatter;
    props.location.state =  props.location.state ? props.location.state : {}
    const redirect_from_page = "redirect_from_page" in props.location.state ? props.location.state.redirect_from_page: '';
    const show_link = (props.location.pathname == '/error');
    const goBack=()=>{
        navigate('/'+redirect_from_page);
    }
    return (
        <Layout>
            <section className="main-section gray min-full-height">
              <div className="container">
                <img src={content.img} alt="Success" />
                <div className="section-heading">{content.header}</div>
                   <p className="section-paragraph">{content.message} {(show_link && redirect_from_page) && <a href="" onClick={goBack} className="section-paragraph">{content.link_message}</a>}</p>
              </div>
            </section>
        </Layout>
    );
};

export default StatusPageTemplate;

export const StatusPageQuery = graphql`
  query StatusPage($id: String!) {
    markdownRemark(id: { eq: $id }) {     
      frontmatter {
        header
        message
        img
        link_message
      }       
    }
  }
`

